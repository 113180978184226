import React, { useState } from 'react';
import { X, RotateCcw, ChevronDown } from 'lucide-react';

const ModernCompare = () => {
  const [purchase, setPurchase] = useState('');
  const [netWorth1, setNetWorth1] = useState('1100000000');
  const [netWorth2, setNetWorth2] = useState('121700');
  const [profile1Name, setProfile1Name] = useState('Taylor Swift');
  const [profile2Name, setProfile2Name] = useState('Average American');
  const [selectedPurchase, setSelectedPurchase] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [showCustomSlider, setShowCustomSlider] = useState(false);

  const commonPurchases = {
    "Daily Expenses": [
      { name: "Cup of Coffee", amount: 5 },
      { name: "Fast Food Meal", amount: 12 },
      { name: "Nice Dinner Out", amount: 100 },
      { name: "Grocery Run", amount: 250 },
    ],
    "Electronics": [
      { name: "iPhone 15 Pro", amount: 999 },
      { name: "MacBook Pro", amount: 2499 },
      { name: "Gaming PC Setup", amount: 3500 },
    ],
    "Transportation": [
      { name: "Used Car", amount: 20000 },
      { name: "New Car", amount: 45000 },
      { name: "Luxury Car", amount: 100000 },
    ],
    "Major Life Events": [
      { name: "Wedding", amount: 30000 },
      { name: "College Tuition (1 Year)", amount: 35000 },
      { name: "House Down Payment", amount: 60000 },
      { name: "Median US House", amount: 430000 },
    ],
  };

  const celebrities = [
    { name: "Elon Musk", netWorth: 234000000000 },
    { name: "Jeff Bezos", netWorth: 180000000000 },
    { name: "Bill Gates", netWorth: 120000000000 },
    { name: "Mark Zuckerberg", netWorth: 106000000000 },
    { name: "Taylor Swift", netWorth: 1100000000 },
    { name: "Tom Cruise", netWorth: 600000000 },
    { name: "Leonardo DiCaprio", netWorth: 300000000 },
    { name: "Jennifer Lawrence", netWorth: 160000000 },
    { name: "Drake", netWorth: 250000000 },
    { name: "Ariana Grande", netWorth: 240000000 },
    { name: "Robert Downey Jr.", netWorth: 400000000 },
    { name: "Dwayne Johnson", netWorth: 800000000 },
    { name: "Kim Kardashian", netWorth: 1800000000 },
    { name: "Jay-Z", netWorth: 2500000000 },
    { name: "Rihanna", netWorth: 1400000000 },
  ];

  const formatCurrency = (value) => {
    if (!value && value !== 0) return '$0';
    if (value < 0.01 && value > 0) {
      return `$${value.toFixed(8)}`;
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  };

  const formatPurchaseName = (name) => {
    const properNouns = ['iPhone', 'MacBook Pro', 'US'];
    let formattedName = name.toLowerCase();
    properNouns.forEach(noun => {
      formattedName = formattedName.replace(
        noun.toLowerCase(),
        noun
      );
    });
    return formattedName;
  };

  const getArticle = (word) => {
    const vowelRegex = '^[aeiou]';
    return word.toLowerCase().match(vowelRegex) ? 'an' : 'a';
  };

  const calculateImpact = () => {
    if (!purchase || !netWorth1 || !netWorth2) return null;
    
    const percentageOfFirst = (Number(purchase) / Number(netWorth1)) * 100;
    const percentageOfSecond = (Number(purchase) / Number(netWorth2)) * 100;
    
    const equivalentForFirst = (percentageOfSecond / 100) * Number(netWorth1);
    const equivalentForSecond = (percentageOfFirst / 100) * Number(netWorth2);
    
    return {
      percentageOfFirst,
      percentageOfSecond,
      equivalentForFirst,
      equivalentForSecond
    };
  };

  const toggleCustomSlider = () => {
    setShowCustomSlider(!showCustomSlider);
    if (!showCustomSlider) {
      setProfile2Name('Custom Amount');
    } else {
      resetToAverageAmerican();
    }
  };

  const resetToAverageAmerican = () => {
    setShowCustomSlider(false);
    setProfile2Name('Average American');
    setNetWorth2('121700');
  };

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setNetWorth2(value.toString());
    setProfile2Name(`Someone worth ${formatCurrency(Number(value))}`);
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    setCustomAmount(value);
    setPurchase(value);
    setSelectedPurchase('custom purchase');
  };

  const flattenedPurchases = Object.entries(commonPurchases).flatMap(([category, items]) =>
    items.map(item => ({
      ...item,
      category
    }))
  );

  const results = calculateImpact();

  return (
    <div className="max-w-3xl mx-auto p-8 min-h-screen bg-zinc-800">
      <div className="text-center mb-16">
        <h1 className="text-5xl font-serif text-mint-100 mb-8">Billionaire Math</h1>
        <img src='/img/subtitle.png' />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="space-y-3">
          <div className="text-mint-100/80 text-sm tracking-wider uppercase mb-2">COMPARE</div>
          <div className="relative">
            <select
              className="w-full bg-transparent text-mint-100 text-2xl py-2 pr-8 pl-0 border-0 border-b-2 border-mint-100/20 focus:border-mint-100/40 focus:ring-0 appearance-none cursor-pointer"
              value={profile1Name}
              onChange={(e) => {
                const selected = celebrities.find(c => c.name === e.target.value);
                if (selected) {
                  setNetWorth1(selected.netWorth.toString());
                  setProfile1Name(selected.name);
                }
              }}
            >
              {celebrities.map(celeb => (
                <option key={celeb.name} value={celeb.name} className="text-zinc-800">
                  {celeb.name}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-mint-100/60 pointer-events-none" />
          </div>
          <div className="text-mint-100/60 text-sm">
            Net Worth: {formatCurrency(Number(netWorth1))}
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-mint-100/80 text-sm tracking-wider uppercase mb-2">TO</div>
          <div className="flex items-center gap-4">
            <div className="text-2xl text-mint-100">{profile2Name}</div>
            <button
              className="bg-transparent p-1 rounded-full hover:bg-mint-100/10 text-mint-100/60 hover:text-mint-100 transition-colors"
              onClick={toggleCustomSlider}
            >
              {showCustomSlider ? <X className="h-4 w-4" /> : <RotateCcw className="h-4 w-4" />}
            </button>
          </div>
          <div className="text-mint-100/60 text-sm">
            Net Worth: {formatCurrency(Number(netWorth2))}
          </div>
          
          {showCustomSlider && (
            <div className="mt-4 space-y-4">
              <input
                type="range"
                min={10000}
                max={1000000}
                step={5000}
                value={Number(netWorth2)}
                onChange={handleSliderChange}
                className="w-full h-1 bg-mint-100/20 rounded-full appearance-none cursor-pointer focus:outline-none [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-yellow-400 [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:w-4 [&::-moz-range-thumb]:h-4 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-yellow-400"
              />
              <div className="flex justify-between text-xs text-mint-100/40">
                <span>$10k</span>
                <span>$1M</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-12 space-y-3">
        <div className="text-mint-100/80 text-sm tracking-wider uppercase mb-2">BUYING A</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="relative">
            <select
              className="w-full bg-transparent text-mint-100 text-2xl py-2 pr-8 pl-0 border-0 border-b-2 border-mint-100/20 focus:border-mint-100/40 focus:ring-0 appearance-none cursor-pointer"
              value={selectedPurchase}
              onChange={(e) => {
                const selected = flattenedPurchases.find(p => p.name === e.target.value);
                if (selected) {
                  setPurchase(selected.amount.toString());
                  setSelectedPurchase(selected.name);
                  setCustomAmount('');
                }
              }}
            >
              <option value="" disabled className="text-zinc-800">Select purchase...</option>
              {flattenedPurchases.map((item) => (
                <option key={item.name} value={item.name} className="text-zinc-800">
                  {item.name} - {formatCurrency(item.amount)}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-mint-100/60 pointer-events-none" />
          </div>

          <div className="relative">
            <input
              type="number"
              placeholder="Enter custom amount..."
              value={customAmount}
              onChange={handleCustomAmountChange}
              className="w-full bg-transparent text-mint-100 text-2xl py-2 pl-0 border-0 border-b-2 border-mint-100/20 focus:border-mint-100/40 focus:ring-0 placeholder-mint-100/40"
            />
          </div>
        </div>
      </div>

      {results && purchase && (
        <div className="mt-12 p-8 rounded-xl bg-zinc-900/50 text-center space-y-8">
          <p className="text-2xl text-mint-100">
            To buy {selectedPurchase === 'custom purchase' ? 
            formatCurrency(Number(purchase)) : 
            `${getArticle(selectedPurchase)} ${formatPurchaseName(selectedPurchase)}`}, 
            which is {results.percentageOfFirst.toFixed(8)}% of {profile1Name}'s net worth, 
            that's basically the same as {profile2Name} spending {formatCurrency(results.equivalentForSecond)}
          </p>
          <p className="text-2xl text-mint-100">
            The equivalent financial impact for {profile1Name} would be 
            spending {formatCurrency(results.equivalentForFirst)}
          </p>
        </div>
      )}
      
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 text-center text-mint-100/60 text-sm">
        Made by <a href="https://instagram.com/jazz.fall" className="text-mint-100 hover:text-mint-100/80">Jazz Fall</a>
      </div>
    </div>
  );
};

export default ModernCompare;